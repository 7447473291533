function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import { debounce } from '../utils/utils';
import { defaultRenderLogoAndTitle } from '../SiderMenu/SiderMenu';
import './index.less';
import BaseMenu from '../SiderMenu/BaseMenu';

var TopNavHeader = function TopNavHeader(props) {
  var ref = useRef(null);
  var theme = props.theme,
      onMenuHeaderClick = props.onMenuHeaderClick,
      contentWidth = props.contentWidth,
      rightContentRender = props.rightContentRender,
      propsClassName = props.className,
      style = props.style;
  var baseClassName = 'ant-pro-top-nav-header';
  var headerDom = defaultRenderLogoAndTitle(props);
  var className = classNames(baseClassName, propsClassName, {
    light: theme === 'light'
  });

  var _useState = useState('auto'),
      _useState2 = _slicedToArray(_useState, 2),
      rightSize = _useState2[0],
      setRightSize = _useState2[1];

  return React.createElement("div", {
    className: className,
    style: style
  }, React.createElement("div", {
    ref: ref,
    className: "".concat(baseClassName, "-main ").concat(contentWidth === 'Fixed' ? 'wide' : '')
  }, headerDom && React.createElement("div", {
    className: "".concat(baseClassName, "-left"),
    onClick: onMenuHeaderClick
  }, React.createElement("div", {
    className: "".concat(baseClassName, "-logo"),
    key: "logo",
    id: "logo"
  }, headerDom)), React.createElement("div", {
    style: {
      flex: 1,
      overflow: 'hidden'
    },
    className: "".concat(baseClassName, "-menu")
  }, React.createElement(BaseMenu, Object.assign({}, props, props.menuProps))), rightContentRender && React.createElement("div", {
    style: {
      minWidth: rightSize
    }
  }, React.createElement(ResizeObserver, {
    onResize: debounce(function (_ref) {
      var width = _ref.width;

      if (!width) {
        return;
      }

      setRightSize(width);
    }, 200)
  }, React.createElement("div", {
    style: {
      paddingRight: 8
    }
  }, rightContentRender(Object.assign({}, props)))))));
};

export default TopNavHeader;