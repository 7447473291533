export default {
  'app.settings.menuMap.basic': '基本设置',
  'app.settings.menuMap.security': '安全设置',
  'app.settings.menuMap.binding': '账号绑定',
  'app.settings.menuMap.notification': '新消息通知',
  'app.settings.basic.avatar': '头像',
  'app.settings.basic.change-avatar': '更换头像',
  'app.settings.basic.email': '邮箱',
  'app.settings.basic.email-message': '请输入您的邮箱!',
  'app.settings.basic.nickname': '昵称',
  'app.settings.basic.nickname-message': '请输入您的昵称!',
  'app.settings.basic.profile': '个人简介',
  'app.settings.basic.profile-message': '请输入个人简介!',
  'app.settings.basic.profile-placeholder': '个人简介',
  'app.settings.basic.country': '国家/地区',
  'app.settings.basic.country-message': '请输入您的国家或地区!',
  'app.settings.basic.geographic': '所在省市',
  'app.settings.basic.geographic-message': '请输入您的所在省市!',
  'app.settings.basic.address': '街道地址',
  'app.settings.basic.address-message': '请输入您的街道地址!',
  'app.settings.basic.phone': '联系电话',
  'app.settings.basic.phone-message': '请输入您的联系电话!',
  'app.settings.basic.update': '更新基本信息',

  'app.settings.basic.tab-login-credentials': '密码登录',
  'app.settings.basic.tab-login-phone': '手机登录',
  'app.settings.basic.login-phone': '手机号',
  'app.settings.basic.login-error': '账户或验证码错误',
  'app.settings.basic.login1-error': '账户或密码错误',
  'app.settings.basic.input-login-accountOrPhone': '请输入用户名/手机号',
  'app.settings.basic.input-login-phone': '请输入手机号',
  'app.settings.basic.login-phone-error': '手机号格式错误',
  'app.settings.basic.login-phone-validator': '该手机号未注册，请先注册账号',
  'app.settings.basic.login-password': '密码',
  'app.settings.basic.login-phone-code': '验证码',
  'app.settings.basic.login-phone-get-code': '获取验证码',
  'app.settings.basic.login-phone-get-code-time': '秒',
  'app.settings.basic.forgot-password': '忘记密码?',
  'app.settings.basic.registered-account': '注册账户',
  'app.settings.basic.other-account': '使用第三方账号登录',


  'app.settings.basic.login': '登录',
  'app.settings.basic.freeRegistration': '注册',
  'app.settings.security.strong': '强',
  'app.settings.security.medium': '中',
  'app.settings.security.weak': '弱',
  'app.settings.security.password': '账户密码',
  'app.settings.security.password-description': '当前密码强度',
  'app.settings.security.phone': '密保手机',
  'app.settings.security.phone-description': '已绑定手机',
  'app.settings.security.question': '密保问题',
  'app.settings.security.question-description': '未设置密保问题，密保问题可有效保护账户安全',
  'app.settings.security.email': '备用邮箱',
  'app.settings.security.email-description': '已绑定邮箱',
  'app.settings.security.mfa': 'MFA 设备',
  'app.settings.security.mfa-description': '未绑定 MFA 设备，绑定后，可以进行二次确认',
  'app.settings.security.modify': '修改',
  'app.settings.security.set': '设置',
  'app.settings.security.bind': '绑定',
  'app.settings.binding.taobao': '绑定淘宝',
  'app.settings.binding.taobao-description': '当前未绑定淘宝账号',
  'app.settings.binding.alipay': '绑定支付宝',
  'app.settings.binding.alipay-description': '当前未绑定支付宝账号',
  'app.settings.binding.dingding': '绑定钉钉',
  'app.settings.binding.dingding-description': '当前未绑定钉钉账号',
  'app.settings.binding.bind': '绑定',
  'app.settings.notification.password': '账户密码',
  'app.settings.notification.password-description': '其他用户的消息将以站内信的形式通知',
  'app.settings.notification.messages': '系统消息',
  'app.settings.notification.messages-description': '系统消息将以站内信的形式通知',
  'app.settings.notification.todo': '待办任务',
  'app.settings.notification.todo-description': '待办任务将以站内信的形式通知',
  'app.settings.open': '开',
  'app.settings.close': '关',
  //客户端首页
  'app.settings.basic.exclusiveManager': '专属经理',
  'app.settings.basic.advisoryService': '咨询客服',
  'app.settings.basic.name': '姓名',
  'app.settings.basic.addWechat': '添加微信、便捷交流',
  'app.settings.basic.noWechat': '暂无微信',
  'app.settings.basic.fullScreen': '全屏',
  'app.settings.basic.inquireNow': '立即询价',
  'app.settings.basic.headerTitle': ' · 钢板切割共享平台',
  'app.settings.basic.headerTitle1': ' 钢板切割行业唯一入选工信部工业互联网平台创新应用案例',
  'app.settings.basic.headerTitle2': '快速报价 高利用率',
  'app.settings.basic.identity': '请选择您需要注册的身份',
  'app.settings.basic.partner': '我是合作伙伴',
  'app.settings.basic.isCustomer': '我是客户',

  'app.settings.basic.customerRegistration': '客户注册',
  'app.settings.basic.customerRegistrationTip': '用户名可设为2-20位字符(包含中英文、数字及下划线)',
  'app.settings.basic.customerName': '用户名:',
  'app.settings.basic.inputAccountName': '请输入用户名:',
  'app.settings.basic.passwordTip': '密码可设为6-20位字符(包含数字、字母、下划线)',
  'app.settings.basic.passwordLabel': '密码:',
  'app.settings.basic.passwordTip1': '请输入密码',
  'app.settings.basic.passwordTip2': '确认密码:',
  'app.settings.basic.passwordTip3': '确认密码',
  'app.settings.basic.phoneLabel': '手机号:',
  'app.settings.basic.inputAccountPhone': '请输入手机号码',
  'app.settings.basic.register-code': '验证码:',
  'app.settings.basic.input-register-code': '请输入验证码',
  'app.settings.basic.get-register-code': '获取验证码',
  'app.settings.basic.register-text': '我已阅读并同意',
  'app.settings.basic.yuncut-user-clause': '《云切网用户协议》',
  'app.settings.basic.user-privacy-clause': '《用户隐私条款》',
  'app.settings.basic.user-privacy-ok': '同意条款并注册',
  'app.settings.basic.had-account': '已有账户?',
  'app.settings.basic.now-login': '立即登录',
  'app.settings.basic.register-other-login': '使用第三方账号登录',
  'app.settings.basic.channels': '获知渠道',
  'app.settings.basic.web_searching': '网络搜索',
  'app.settings.basic.short_video_platform': '短视频平台',
  'app.settings.basic.exhibition': '展会',
  'app.settings.basic.friend_referral': '朋友转介绍',
  'app.settings.basic.other': '其它',



  'app.settings.basic.header_text1': '一站式钢板切割共享平台',
  'app.settings.basic.header_text31': '快 速 报 价',
  'app.settings.basic.header_text32': '高 利 用 率',
  'app.settings.basic.header_text4': '立即获取报价',
  'app.settings.basic.online_cutting_header': '一站式钢板切割定制服务',
  'app.settings.basic.header_desc': '云切在线将AI智能算法引入机加工报价中，带给您前所未有的美好体验。',
  'app.settings.basic.report_price': '仅需两步，3秒内完成报价',
  'app.settings.basic.tip_title': '上传零件图纸',
  'app.settings.basic.tip_title1': '支持文件格式dwg,dxf格式',
  'app.settings.basic.tip_title2': '所有的上传都是安全和保密的',
  'app.settings.basic.text1_times': '2019年至今',
  'app.settings.basic.model_factory_footer_text2': '云切在线通过提高钢板利用率共节约',
  'app.settings.basic.model_factory_footer_text3': '吨钢板',
  'app.settings.basic.model_factory_footer_text4': '减少的碳排放量相当于',
  'app.settings.basic.model_factory_footer_text5': '亩森林，一年CO',
  'app.settings.basic.model_factory_footer_text6': '吸收量',
  'app.settings.basic.model_factory_footer_phone_text': '咨询热线',
  'app.settings.basic.model_factory_footer_email': '邮 箱: ',
  'app.settings.basic.model_factory_footer_address': '地 址: 浙江省嘉兴市秀洲区秀园路北科建创新园42幢102-2',




  'app.settings.basic.modelTitle': '平台优势',
  'app.settings.basic.operationFlow': '业务流程',
  'app.settings.basic.operationFlowText': '将AI智能算法引入机加工报价中，带给您前所未有的美好体验。',
  'app.settings.basic.operationFlowText1': '仅需两步，3秒内完成报价',


  'app.settings.basic.modelFactory': '样板工厂',
  'app.settings.basic.enterpriseHonor': '企业荣誉',
  'app.settings.basic.enterpriseValues': '企业价值观',


  'app.settings.basic.onlineConsultation': '在线咨询',
  'app.settings.basic.zaixiankefu': '在线客服',
  'app.settings.basic.xiaochengxu': '小程序',
  'app.settings.basic.gongzhonghao': '公众号',
  'app.settings.basic.huidaodingbu': '回到顶部',


  'app.settings.basic.aboutUs': '关于我们',
  'app.settings.basic.contactUs': '联系我们',
  'app.settings.basic.customerService': '联系客服',
  'app.settings.basic.collaborativeRecruitment': '合作招聘',
  'app.settings.basic.privacyPolicy': '隐私政策',
  'app.settings.basic.telephone': '联系电话',
  'app.settings.basic.contactAddress': '联系地址：浙江省嘉兴市秀洲区秀园路北科建创新园42幢102-2',
  'app.settings.basic.weChatCustomerService': '微信客服',
  'app.settings.basic.weChatMiniProgram': '微信小程序',
  'app.settings.basic.weChatofficialAccount': '微信公众号',

  //询价页面（未登录）
  'app.settings.inquiry.modelTitle': '询价信息',
  'app.settings.inquiry.demonstrate': '询价步骤演示',
  'app.settings.inquiry.receivingArea': '收货区域',
  'app.settings.inquiry.selectArea': '请选择收获区域',
  'app.settings.inquiry.partsInformation': '零件信息',
  //询价步骤演示
  'app.settings.inquiry.improvePartInfo': '框选图纸并完善零件信息',
  'app.settings.inquiry.jumpToFormal': '登录并跳转到正式询价',
  'app.settings.inquiry.nextStep': '下一步',
  'app.settings.inquiry.previousStep': '上一步',
  'app.settings.inquiry.skip': '跳过',

  'app.settings.inquiry.complete': '完成',
  'app.settings.inquiry.uploadDrawings': '上传图纸',
  'app.settings.inquiry.completePartInfo': '请完善零件信息',
  'app.settings.inquiry.providesMaterials': '云切提供材料',
  'app.settings.inquiry.cuttingPrograms': '云切编制切割程序',

  // 'app.settings.inquiry.cuttingPrograms': '云切编制切割程序',
  //底部栏
  'app.settings.inquiry.totalPrice': '总价',
  'app.settings.inquiry.includingTaxes': '(包含税费)',
  'app.settings.inquiry.quotationAmount': '(只包含报价零件的金额)',
  'app.settings.inquiry.jumpInquiry': '跳转正式询价',
  'app.settings.inquiry.bulkInquiry': '跳转批量询价',

  'app.settings.inquiry.dragUpload': '点击或拖拽上传零件图纸',
  'app.settings.inquiry.supportsFormats': '支持dwg、dxf格式',
  'app.settings.inquiry.verificationPassed': '校验通过',
  //图纸更换、显示
  'app.settings.inquiry.effectiveArea': '有效区域',
  'app.settings.inquiry.selectionDemo': '框选图纸演示',
  'app.settings.inquiry.largeImage': '查看大图',
  'app.settings.inquiry.replaceDrawing': '点此更换图纸',
  'app.settings.inquiry.parsingFailed': '解析失败',

  'app.settings.inquiry.proportion': '图纸:实物',
  'app.settings.inquiry.drawingScale': '图纸比例',
  //询价提示、、图纸上传失败提示
  'app.settings.inquiry.uploadFailed': '上传图纸失败',
  'app.settings.inquiry.uploadFailedTip': '文件名称不符合要求，或文件类型不符合要求，请上传dwg、dxf格式的零件图纸',
  'app.settings.inquiry.service': '客服电话',
  'app.settings.inquiry.parsingFailedTip': '图纸解析失败或报价失败,已转到人工报价',
  'app.settings.inquiry.inquiryFailed': '询价失败',

  'app.settings.inquiry.noDrawing': '请上传图纸',
  'app.settings.inquiry.addFailed': '新增失败',
  'app.settings.inquiry.restore': '是否恢复上次录入的内容',
  'app.settings.inquiry.completeInfo': '请完善信息',
  //零件信息（标题）
  'app.settings.parts.thumbnail': '缩略图',
  'app.settings.parts.baseInfo': '基础信息',
  'app.settings.parts.materialInfo': '材质信息',
  'app.settings.parts.priceInfo': '价格信息',
  'app.settings.parts.postProcess': '后工序',

  'app.settings.parts.specifications': '规格信息',
  'app.settings.parts.specialRequest': '特殊要求',
  'app.settings.parts.createTime': '创建时间',
  'app.settings.parts.notes': '备注',
  //零件基础信息
  'app.settings.parts.drawingNoAndVersion': '图号-版本号',
  'app.settings.parts.drawingNo': '图号',
  'app.settings.parts.version': '版本号',
  'app.settings.parts.customerVersion': '客户版本号',
  'app.settings.parts.partsName': '名称',

  'app.settings.parts.outerContour': '矩形外轮廓',
  'app.settings.parts.validity': '有效期',
  'app.settings.parts.numberOfParts': '数量',
  'app.settings.parts.effectiveTime': '含报价当天,到最后工作日的24点',
  //单位
  'app.settings.parts.piece': '件',
  'app.settings.parts.piece1': '个',
  //零件材质信息
  'app.settings.parts.cuttingProcess': '切割工艺',
  'app.settings.parts.materialQuality': '材质',
  'app.settings.parts.plateThickness': '板厚',
  'app.settings.parts.steelMill': '钢厂',
  //零件价格信息
  'app.settings.parts.materialExpense': '材料费',
  'app.settings.parts.processingExpense': '加工费',
  'app.settings.parts.unitPrice': '单价',
  'app.settings.parts.totalPrice': '总价',
  'app.settings.parts.priceDetails': '含明细',

  'app.settings.parts.includingMP': '含材料费、加工费',
  'app.settings.parts.includingTax': '含税单价',
  'app.settings.parts.taxRate': '税率',
  'app.settings.parts.discount': '折扣',
  'app.settings.parts.discountTip': '折扣信息提示',

  'app.settings.parts.expired': '已过期',

  //零件规格信息
  'app.settings.parts.perimeter': '周长',
  'app.settings.parts.holes': '穿孔',
  'app.settings.parts.area': '面积',
  'app.settings.parts.weight': '重量',
  'app.settings.parts.aspect': '宽×长',
  //切割工艺
  'app.settings.parts.laserCutting': '激光切割',
  'app.settings.parts.flameCutting':  '火焰切割',
  'app.settings.parts.plasmaCutting': '等离子切割',

  //零件信息输入提示
  'app.settings.parts.enterRequireTip': '请输入特殊需求',
  'app.settings.parts.enterRemarksTip': '可输入100个字符以内',
  'app.settings.parts.enterDrawingNoTip': '请输入图号',
  'app.settings.parts.enterVersionTip': '请输入版本号',
  'app.settings.parts.enterNameTip': '请输入名称',

  'app.settings.parts.existence': '图号-版本号已存在',
  'app.settings.parts.enterDVTip': '请输入图号和版本号',
  //操作
  'app.settings.operation.operation': '操作',
  'app.settings.operation.delete': '删除',
  'app.settings.operation.add': '添加',
  'app.settings.operation.modify': '修改',
  'app.settings.operation.yes': '是',
  'app.settings.operation.no': '否',

  'app.settings.operation.confirm': '确认',
  'app.settings.operation.cancel': '取消',

  //地址
  'app.settings.address.Tokyo': '东京',
  'app.settings.address.Osaka': '大阪',

  //反馈信息提示
  'app.settings.common.success': '上传成功',
  'app.settings.common.failed': '上传失败',
  'app.settings.common.reUpload': '上传文件失败，请重新上传',


};
