export default {
  'app.settings.menuMap.basic': 'Basic Settings',
  'app.settings.menuMap.security': 'Security Settings',
  'app.settings.menuMap.binding': 'Account Binding',
  'app.settings.menuMap.notification': 'New Message Notification',
  'app.settings.basic.avatar': 'Avatar',
  'app.settings.basic.change-avatar': 'Change avatar',
  'app.settings.basic.email': 'Email',
  'app.settings.basic.email-message': 'Please input your email!',
  'app.settings.basic.nickname': 'Nickname',
  'app.settings.basic.nickname-message': 'Please input your Nickname!',
  'app.settings.basic.profile': 'Personal profile',
  'app.settings.basic.profile-message': 'Please input your personal profile!',
  'app.settings.basic.profile-placeholder': 'Brief introduction to yourself',
  'app.settings.basic.country': 'Country/Region',
  'app.settings.basic.country-message': 'Please input your country!',
  'app.settings.basic.geographic': 'Province or city',
  'app.settings.basic.geographic-message': 'Please input your geographic info!',
  'app.settings.basic.address': 'Street Address',
  'app.settings.basic.address-message': 'Please input your address!',
  'app.settings.basic.phone': 'Phone Number',
  'app.settings.basic.phone-message': 'Please input your phone!',
  'app.settings.basic.update': 'Update Information',

  'app.settings.basic.tab-login-credentials': 'Password Login',
  'app.settings.basic.tab-login-phone': 'Mobile Login',
  'app.settings.basic.login-phone': 'Mobile Number',
  'app.settings.basic.login-error': 'Account or Verification Code Error',
  'app.settings.basic.login1-error': 'Account or Password Error',
  'app.settings.basic.input-login-accountOrPhone': 'Please Enter Username/Mobile Number',
  'app.settings.basic.input-login-phone': 'Please Enter Mobile Number',
  'app.settings.basic.login-phone-error': 'Mobile Number Format Error',
  'app.settings.basic.login-phone-validator': 'This Mobile Number is Not Registered, Please Register Account First',
  'app.settings.basic.login-password': 'Password',
  'app.settings.basic.login-phone-code': 'Verification Code',
  'app.settings.basic.login-phone-get-code': 'Get Verification Code',
  'app.settings.basic.login-phone-get-code-time': 'Seconds',
  'app.settings.basic.forgot-password': 'Forgot Password?',
  'app.settings.basic.registered-account': 'Register Account',
  'app.settings.basic.other-account': 'Login with Other Account',

  'app.settings.basic.login': 'login',
  'app.settings.basic.freeRegistration': 'register',

  'app.settings.security.strong': 'Strong',
  'app.settings.security.medium': 'Medium',
  'app.settings.security.weak': 'Weak',
  'app.settings.security.password': 'Account Password',
  'app.settings.security.password-description': 'Current password strength',
  'app.settings.security.phone': 'Security Phone',
  'app.settings.security.phone-description': 'Bound phone',
  'app.settings.security.question': 'Security Question',
  'app.settings.security.question-description':
    'The security question is not set, and the security policy can effectively protect the account security',
  'app.settings.security.email': 'Backup Email',
  'app.settings.security.email-description': 'Bound Email',
  'app.settings.security.mfa': 'MFA Device',
  'app.settings.security.mfa-description':
    'Unbound MFA device, after binding, can be confirmed twice',
  'app.settings.security.modify': 'Modify',
  'app.settings.security.set': 'Set',
  'app.settings.security.bind': 'Bind',
  'app.settings.binding.taobao': 'Binding Taobao',
  'app.settings.binding.taobao-description': 'Currently unbound Taobao account',
  'app.settings.binding.alipay': 'Binding Alipay',
  'app.settings.binding.alipay-description': 'Currently unbound Alipay account',
  'app.settings.binding.dingding': 'Binding DingTalk',
  'app.settings.binding.dingding-description': 'Currently unbound DingTalk account',
  'app.settings.binding.bind': 'Bind',
  'app.settings.notification.password': 'Account Password',
  'app.settings.notification.password-description':
    'Messages from other users will be notified in the form of a station letter',
  'app.settings.notification.messages': 'System Messages',
  'app.settings.notification.messages-description':
    'System messages will be notified in the form of a station letter',
  'app.settings.notification.todo': 'To-do Notification',
  'app.settings.notification.todo-description':
    'The to-do list will be notified in the form of a letter from the station',
  'app.settings.open': 'Open',
  'app.settings.close': 'Close',
  'app.settings.basic.exclusiveManager': 'ExclusiveManager',
  'app.settings.basic.advisoryService': 'AdvisoryService',
  'app.settings.basic.name': 'name',
  'app.settings.basic.addWechat': 'Add WeChat for convenient communication',
  'app.settings.basic.noWechat': 'No WeChat yet',
  'app.settings.basic.fullScreen': 'Full Screen',
  'app.settings.basic.inquireNow': 'INQUIRE',
  'app.settings.basic.headerTitle': '· Steel plate cutting shared platform',
  'app.settings.basic.headerTitle1': 'The only innovative application case in the steel plate cutting industry selected for the Industrial Internet Platform of the Ministry of Industry and Information Technology',
  'app.settings.basic.headerTitle2': 'Quick quote High utilization rate',
  'app.settings.basic.identity': 'Please select the identity you need to register',
  'app.settings.basic.partner': 'I am a partner',
  'app.settings.basic.isCustomer': 'I am a customer',

  'app.settings.basic.customerRegistration': 'Customer Registration',
  'app.settings.basic.customerRegistrationTip': 'The username can be set to 2-20 characters (including Chinese, English, numbers, and underscores)',
  'app.settings.basic.customerName': 'Username:',
  'app.settings.basic.inputAccountName': 'Please enter a username:',
  'app.settings.basic.passwordTip': 'The password can be set to 6-20 characters (including numbers, letters, and underscores)',
  'app.settings.basic.passwordLabel': 'Password:',
  'app.settings.basic.passwordTip1': 'Please enter a password',
  'app.settings.basic.passwordTip2': 'Confirm Password:',
  'app.settings.basic.passwordTip3': 'Confirm Password',
  'app.settings.basic.phoneLabel': 'Mobile Number:',
  'app.settings.basic.inputAccountPhone': 'Please enter your mobile phone number',
  'app.settings.basic.register-code': 'Verification Code:',
  'app.settings.basic.input-register-code': 'Please enter the verification code',
  'app.settings.basic.get-register-code': 'Get Verification Code',
  'app.settings.basic.register-text': 'I have read and agree to',
  'app.settings.basic.yuncut-user-clause': '《Yuncut User Agreement》',
  'app.settings.basic.user-privacy-clause': '《User Privacy Terms》',
  'app.settings.basic.user-privacy-ok': 'Agree to terms and register',
  'app.settings.basic.had-account': 'Already have an account?',
  'app.settings.basic.now-login': 'Log in now',
  'app.settings.basic.register-other-login': 'Log in with a third-party account',
  'app.settings.basic.channels': 'Learn about channels',
  'app.settings.basic.web_searching': 'web searching',
  'app.settings.basic.short_video_platform': 'short video platform',
  'app.settings.basic.exhibition': 'exhibition',
  'app.settings.basic.friend_referral': 'friend referral',
  'app.settings.basic.other': 'other',

  'app.settings.basic.header_text1': 'One-stop steel plate cutting sharing platform',
  'app.settings.basic.header_text31': 'Quick quotation',
  'app.settings.basic.header_text32': 'High utilization rate',
  'app.settings.basic.header_text4': 'Get a quote now',
  'app.settings.basic.online_cutting_header': 'One-stop steel plate cutting customization service',
  'app.settings.basic.header_desc': 'Yunqie Online introduces AI intelligent algorithms into machining quotation, bringing you an unprecedented wonderful experience.',
  'app.settings.basic.report_price': 'Only two steps, complete quotation within 3 seconds',
  'app.settings.basic.tip_title': 'Upload part drawings',
  'app.settings.basic.tip_title1': 'Support file formats dwg, dxf',
  'app.settings.basic.tip_title2': 'All uploads are safe and confidential',
  'app.settings.basic.text1_times': 'From 2019 to the present',
  'app.settings.basic.model_factory_footer_text2': 'Yunqie Online saves by improving the utilization rate of steel plates',
  'app.settings.basic.model_factory_footer_text3': 'tons of steel plates',
  'app.settings.basic.model_factory_footer_text4': 'The reduced carbon emissions are equivalent to',
  'app.settings.basic.model_factory_footer_text5': 'acres of forest, one year CO',
  'app.settings.basic.model_factory_footer_text6': 'absorption',
  'app.settings.basic.model_factory_footer_phone_text': 'Hotline',
  'app.settings.basic.model_factory_footer_email': 'Email: ',
  'app.settings.basic.model_factory_footer_address': 'Address: Building 42, 102-2, North Kejian Innovation Park, Xiuyuan Road, Xiuzhou District, Jiaxing City, Zhejiang Province',


  'app.settings.basic.modelTitle': 'PLATFORM ADVANTAGES',
  'app.settings.basic.operationFlow': 'OPERATION FLOW',
  'app.settings.basic.operationFlowText': 'Introducing AI intelligent algorithms into machining quotations will bring you an unprecedented experience.',
  'app.settings.basic.operationFlowText1': 'Just two steps to complete the quote within 3 seconds',

  'app.settings.basic.modelFactory': 'MODEL FACTORY',
  'app.settings.basic.enterpriseHonor': 'ENTERPRISE HONOR',
  'app.settings.basic.enterpriseValues': 'ENTERPRISE VALUES',

  'app.settings.basic.onlineConsultation': 'Online Consultation',
  'app.settings.basic.zaixiankefu': 'Online Service',
  'app.settings.basic.xiaochengxu': 'Applets',
  'app.settings.basic.gongzhonghao': 'No Public',
  'app.settings.basic.huidaodingbu': 'Top',

  'app.settings.basic.aboutUs': 'About Us',
  'app.settings.basic.contactUs': 'Contact Us',
  'app.settings.basic.customerService': 'Customer Service',
  'app.settings.basic.collaborativeRecruitment': 'Collaborative Recruitment',
  'app.settings.basic.privacyPolicy': 'Privacy Policy',
  'app.settings.basic.telephone': 'Contact Telephone',
  'app.settings.basic.contactAddress': 'Contact Address: Building 42, 102-2, Kejian Innovation Park, Xiuyuan Road North, Xiuzhou District, Jiaxing City, Zhejiang Province',
  'app.settings.basic.weChatCustomerService': 'WeChat Customer Service',
  'app.settings.basic.weChatMiniProgram': 'WeChat Mini Program',
  'app.settings.basic.weChatofficialAccount': 'WeChat Official Account',

  //询价页面（未登录）
  'app.settings.inquiry.modelTitle': 'Inquiry information',
  'app.settings.inquiry.demonstrate': 'Demonstration of Inquiry Steps',
  'app.settings.inquiry.receivingArea': 'Receiving area',
  'app.settings.inquiry.selectArea': 'Please select the receiving area',
  'app.settings.inquiry.partsInformation': 'Part Information',//零件信息

  //询价步骤演示
  'app.settings.inquiry.improvePartInfo': 'Frame select drawings and improve part information',
  'app.settings.inquiry.jumpToFormal': 'Login and redirect to the official inquiry',
  'app.settings.inquiry.nextStep': 'Next step',
  'app.settings.inquiry.previousStep': 'Previous step',
  'app.settings.inquiry.skip': 'skip',

  'app.settings.inquiry.complete': 'complete',
  'app.settings.inquiry.uploadDrawings': 'Upload drawings',
  'app.settings.inquiry.completePartInfo': 'Please provide complete part information',
  'app.settings.inquiry.providesMaterials': 'Yuncut provides materials',
  'app.settings.inquiry.cuttingPrograms': 'Yuncut programming for cutting programs',
  //底部栏
  'app.settings.inquiry.totalPrice': 'Total price',
  'app.settings.inquiry.includingTaxes': '(Including taxes and fees)',
  'app.settings.inquiry.quotationAmount': '(Only including the amount of quoted parts)',

  'app.settings.inquiry.jumpInquiry': 'Jump to formal inquiry',
  'app.settings.inquiry.bulkInquiry': 'Jump to bulk inquiry',
  'app.settings.inquiry.dragUpload': 'Click or drag to upload part drawings',
  'app.settings.inquiry.supportsFormats': 'Supports dwg and dxf formats',
  'app.settings.inquiry.verificationPassed': 'Verification passed',
  //询价中图片操作
  'app.settings.inquiry.effectiveArea': 'effective area',
  'app.settings.inquiry.selectionDemo': 'Frame selection drawing demonstration',
  'app.settings.inquiry.largeImage': 'View large image',
  'app.settings.inquiry.replaceDrawing': 'Click here to replace the drawing',
  'app.settings.inquiry.parsingFailed': 'Parsing failed',

  'app.settings.inquiry.proportion': 'Drawing:Physical object',
  'app.settings.inquiry.drawingScale': 'Drawing Scale',
  //询价提示、、图纸上传失败提示
  'app.settings.inquiry.uploadFailed': 'Upload drawing failed',
  'app.settings.inquiry.uploadFailedTip': 'The file name does not meet the requirements, or the file type does not meet the requirements. Please upload the part drawings in dwg or dxf format',
  'app.settings.inquiry.service': 'customer service telephone numbers',
  'app.settings.inquiry.parsingFailedTip': 'The customer service hotline failed to parse the drawings or provide a quotation, which has been transferred to manual reporting',
  'app.settings.inquiry.inquiryFailed': 'Inquiry failed',

  'app.settings.inquiry.noDrawing': 'Please upload the drawings',
  'app.settings.inquiry.addFailed': 'New addition failed',
  'app.settings.inquiry.restore': 'Do you want to restore the content entered last time',
  'app.settings.inquiry.completeInfo': 'Please complete the information',

  //零件信息（标题）
  'app.settings.parts.thumbnail': 'thumbnail',//缩略图
  'app.settings.parts.baseInfo': 'basic information',
  'app.settings.parts.materialInfo': 'material information',
  'app.settings.parts.priceInfo': 'price',
  'app.settings.parts.postProcess': 'post process',
  'app.settings.parts.specifications': 'specification information',
  'app.settings.parts.specialRequest': 'special requirements',
  'app.settings.parts.createTime': 'create Time',
  'app.settings.parts.notes': 'remarks',//备注
  //零件基础信息
  'app.settings.parts.drawingNoAndVersion': 'drawing number-version number',
  'app.settings.parts.drawingNo': 'drawing number',
  'app.settings.parts.version': 'version number',
  'app.settings.parts.customerVersion': 'customer version number',
  'app.settings.parts.partsName': 'part name',

  'app.settings.parts.outerContour': 'outer contour',//rectangular outer contour
  'app.settings.parts.validity': 'period of validity',
  'app.settings.parts.numberOfParts': 'number of parts',
  'app.settings.parts.effectiveTime': 'Including the day of quotation, until 24:00 on the last working day',
  //单位
  'app.settings.parts.piece': 'piece',//件
  'app.settings.parts.piece1': 'piece',//个
  //零件材质信息
  'app.settings.parts.cuttingProcess': 'cutting process',//切割工艺
  'app.settings.parts.materialQuality': 'material quality',
  'app.settings.parts.plateThickness': 'plate thickness',
  'app.settings.parts.steelMill': 'steel mill',//钢厂
  //零件价格信息
  'app.settings.parts.materialExpense': 'material expense',
  'app.settings.parts.processingExpense': 'processing expense',
  'app.settings.parts.unitPrice': 'unit price',
  'app.settings.parts.totalPrice': 'total price',
  'app.settings.parts.priceDetails': 'including details',

  'app.settings.parts.includingMP': 'Including material and processing expense',
  'app.settings.parts.includingTax': 'unit price including tax',
  'app.settings.parts.taxRate': 'tax rate',
  'app.settings.parts.discount': 'discount',
  'app.settings.parts.discountTip': 'discount information prompt',

  'app.settings.parts.expired': 'expired',
  //零件规格信息
  'app.settings.parts.perimeter': 'perimeter',//周长
  'app.settings.parts.holes': 'bore',//打孔
  'app.settings.parts.area': 'area',
  'app.settings.parts.weight': 'weight',
  'app.settings.parts.aspect': 'width x length',
  //切割工艺
  'app.settings.parts.laserCutting': 'Laser Cutting',
  'app.settings.parts.flameCutting': 'Flame Cutting',
  'app.settings.parts.plasmaCutting': 'Plasma Cutting',

  //零件信息输入提示
  'app.settings.parts.enterRequireTip': 'Please enter special requirements',
  'app.settings.parts.enterRemarksTip': 'Can input up to 100 characters',
  'app.settings.parts.enterDrawingNoTip': 'Please enter the drawing number',
  'app.settings.parts.enterVersionTip': 'Please enter the version number',
  'app.settings.parts.enterNameTip': 'Please enter a name',

  'app.settings.parts.existence': 'Image number - version number already exists',
  'app.settings.parts.enterDVTip': 'Please enter the drawing number and version number',
  //操作
  'app.settings.operation.operation': 'operation',
  'app.settings.operation.delete': 'delete',
  'app.settings.operation.add': 'add',
  'app.settings.operation.modify': 'modify',
  'app.settings.operation.yes': 'yes',
  'app.settings.operation.no': 'no',

  'app.settings.operation.confirm': 'confirm',
  'app.settings.operation.cancel': 'cancel',
  //地址
  'app.settings.address.Tokyo': 'Tokyo',
  'app.settings.address.Osaka': 'Osaka',

  //信息提示
  'app.settings.common.success': 'Upload successful',
  'app.settings.common.failed': 'Upload failed',
  'app.settings.common.reUpload': 'Failed to upload file, please upload again',

};
